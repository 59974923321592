import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Form, Spinner, Dropdown, Button, DropdownButton, ButtonGroup, OverlayTrigger, Popover, Modal, Tabs, Tab, Table } from "react-bootstrap";
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import http from '../services/HttpService';
import { AuthenticationService } from '../services/AuthenticationService';
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

const toasterOptions = {
    hideProgressBar: true,
    autoClose: 5000,
    closeButton: false,
    position: toast.POSITION.BOTTOM_LEFT,
    theme: "colored"
}

export default function EditPersona() {
    const navigate = useNavigate();

    useEffect(() => {
        if (AuthenticationService.is_valid()) {
            document.title = "Cognitista - Edit Persona";
        } else {
            navigate('/auth/login', { replace: true });
        }
        getPersonaList();
    }, []);

    const [params, setParams] = useState({
        flg: 'edit',
        loading: true,
        showPersona: true,
    });
    
    const [showDiv, setshowDiv] = useState(true);

    const [selectOptions, setselectOptions] = useState({
        personaList: [],
    });

    const getPersonaList = () => {
        let url = '/_api/getTitles';
        http.get(url, {})
        .then(res => {
            if (res.data.status === 'success') {
                setselectOptions({ ...selectOptions, personaList: res.data.data });
                setParams({ loading: false, flg: 'edit', showPersona: true});
            }
        });
    }

    const [personaData, setPersonaData] = useState({
        id: "",
        title: "",
        desc: ""
    });

    const getPersona = (val) => {
        let params = {
            syst_prompt_id: val.value
        }
        let url = '/_api/getPersona';
        http.get(url, { params })
            .then(res => {
                if (res.data.status === 'success') {
                    let response = res.data.data;
                    setPersonaData({ ...personaData, id: response.id, title: response.title, desc: response.description });
                }
            });
    }

    const handleEditPersona = (event) => {
        var key = event.target?.name;
        var val = event.target?.value;
        setPersonaData({ ...personaData, [key]: val })
    }

    const savePersona = () => {
        const paramsObj = {
            id: personaData.id,
            title: personaData.title,
            description: personaData.desc
        }
        if(personaData.title==='') {
            toast.error("Title required", toasterOptions);
            return false;
        }
        if(personaData.desc==='') {
            toast.error("Description required", toasterOptions);
            return false;
        }
        let url = '/_api/updatePersona';
        if(params.flg==='add') {
            url = '/_api/saveTitle';
        }
        setParams({ ...params, loading: true });
        http.post(url, paramsObj)
        .then(res => {
            if (res.data.status === 'success') {
                toast.success(res.data.message, toasterOptions);
                if(params.flg==='add') {
                    setPersonaData({ id: "", title: "", desc: "" });
                }
                getPersonaList();
            }
        });
    }

    const addNewPersona = (flg) => {
        setPersonaData({ id: "", title: "", desc: "" });
        setTimeout(function() {
            if(flg==='add') {            
                setParams({ loading: false, flg: 'add', showPersona: false});
            } else {
                setParams({ loading: false, flg: 'edit', showPersona: true});
            }
        }, 100);
    }

    const clearForm = () => {
        setPersonaData({ id: "", title: "", desc: "" });
    }
    return (
        <>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <Row>
                    <Col className="bg-white shadow-sm centerBox p-5" sm={{ span: 10, offset: 1 }}>
                        <Row>
                            <Col md={{ span: 7 }} className="text-center text-md-left">
                                <div className="">
                                    <span className="fs-22 font-weight-bold">
                                        Cognitista <span className="fs-21 title-yellow-bg px-1 font-weight-boldest">AI</span>
                                    </span>
                                    <div className="d-none text-muted fs-10"><span className="ml-5">(Private beta ver 0.3)</span></div>
                                </div>
                            </Col>
                            <Col md={{ span: 5 }} className="text-center text-md-right mt-md-1 mt-2">
                                <div className="d-flex align-items-center justify-content-center justify-content-md-end">
                                    {params.flg==='edit' && <Button variant="outline-light yellow-bg" size="sm" type="button" title="" onClick={()=>addNewPersona('add')}><FontAwesomeIcon icon="fa-plus" className="mr-2" />Add new</Button>}
                                    {params.flg==='add' && <Button variant="outline-light yellow-bg" size="sm" type="button" title="" onClick={()=>addNewPersona('edit')}><FontAwesomeIcon icon="edit" className="mr-2" />Edit</Button>}
                                </div>
                            </Col>
                        </Row>
                        <div className="mt-4">
                            {params.showPersona && <div className="d-flex flex-column">
                                <span>Select persona to edit</span>
                                <div className="mt-2 w-md-350px w-100">
                                    <Select name="" id="" options={selectOptions.personaList.map((v) => ({ value: v.id, label: v.label }))} placeholder="Custom Persona" onChange={(e) => getPersona(e)} value={selectOptions.personaList.filter(option => option.id === personaData.id)} />
                                </div>
                            </div>}
                            <Form onSubmit={e => e.preventDefault()} className="d-flex flex-column gap-4 mt-5">
                                <Form.Group>
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control name="title" type="text" placeholder="Enter Title" value={personaData.title} onChange={handleEditPersona} />
                                </Form.Group>
                                <Form.Group className="mt-2">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control name="desc" as="textarea" rows={5} placeholder="Enter Description" value={personaData.desc} onChange={handleEditPersona} style={{resize: 'none'}} />
                                </Form.Group>
                                <div className="mt-2 d-flex justify-content-end">
                                    <Button className="btn-sm" type='button' variant="secondary" onClick={clearForm} disabled={params.loading}>Cancel</Button>
                                    <Button className="btn-sm ml-2 border-0" type='button' variant="outline-light yellow-bg" onClick={savePersona} disabled={params.loading}>{params.loading && <Spinner animation="border" size="sm" className="mr-2"></Spinner>}Save</Button>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
            <ToastContainer />
        </>
    )
}