import React from 'react'
import { LinkContainer } from 'react-router-bootstrap';
import { Nav, Dropdown, Navbar, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { AuthenticationService } from '../services/AuthenticationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Header = () => {
	return (
		<>
			<Navbar sticky="top" collapseOnSelect expand="sm" variant='light' className='shadow-sm customBorderBottom2 bg-light'>
				<Container>
					<Navbar.Brand href="/" className='p-0 customClr'></Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="mr-auto"></Nav>
						<Nav className="ml-auto mr-5 fs-5">
							{/* <LinkContainer to='/'><Nav.Link>Custom</Nav.Link></LinkContainer>*/}
							<LinkContainer to='/v1'><Nav.Link>Standard</Nav.Link></LinkContainer>
							<LinkContainer to='/v2'><Nav.Link>Standard Medium Context</Nav.Link></LinkContainer>
							<LinkContainer to='/v3'><Nav.Link>Advanced Standard Context</Nav.Link></LinkContainer>
							<LinkContainer to='/v4'><Nav.Link>Standard Ultra Context</Nav.Link></LinkContainer>
							<LinkContainer to='/v5'><Nav.Link>Advanced Ultra Context</Nav.Link></LinkContainer>
							{/* <Dropdown className='' align='right'>
								<Dropdown.Toggle className="title-yellow-bg text-dark" size={"sm"} id="dropdown-basic">
									Cognitista Model-1<FontAwesomeIcon className="ml-1 fs-icon" icon="caret-down" />
								</Dropdown.Toggle>
								<Dropdown.Menu className="rounded-2 yellow-bdr-1px">
									<LinkContainer to='/'><Dropdown.Item>Custom</Dropdown.Item></LinkContainer>
									<LinkContainer to='/v1'><Dropdown.Item>Standard</Dropdown.Item></LinkContainer>
									<LinkContainer to='/v2'><Dropdown.Item>Standard Medium Context</Dropdown.Item></LinkContainer>
								</Dropdown.Menu>
							</Dropdown>
							<Dropdown className='ml-2' align='right'>
								<Dropdown.Toggle className="title-yellow-bg text-dark" size={"sm"} id="dropdown-basic">
									Cognitista Model-2 <FontAwesomeIcon className="ml-1 fs-icon" icon="caret-down" />
								</Dropdown.Toggle>
								<Dropdown.Menu className="rounded-2 yellow-bdr-1px">
									<LinkContainer to='/v3'><Dropdown.Item>Advanced Standard Context</Dropdown.Item></LinkContainer>
									<LinkContainer to='/v4'><Dropdown.Item>Standard Ultra Context</Dropdown.Item></LinkContainer>
									<LinkContainer to='/v5'><Dropdown.Item>Advanced Ultra Context</Dropdown.Item></LinkContainer>
								</Dropdown.Menu>
							</Dropdown> */}
						</Nav>
						<Nav>
							<Dropdown>
								<Dropdown.Toggle style={{
									background: "none",
									border: "0",
									fontSize: "11pt",
									padding: "0",
									color: '#353740'
								}} id="dropdown-basic">
									{AuthenticationService.get_name()}
									<FontAwesomeIcon className="ml-3 fs-icon" icon="bars" />
								</Dropdown.Toggle>
								<Dropdown.Menu className='yellow-bdr-1px'>
									{/* <Dropdown.Item as={Link} to="/profile">My profile</Dropdown.Item>
								<Dropdown.Item as={Link} to={`/change-password`} className="flex-column">Change password</Dropdown.Item> */}
									{AuthenticationService.get_rights().is_admin && <Dropdown.Item as={Link} to="/control-panel">Control Panel</Dropdown.Item>}
									<Dropdown.Divider />
									<Dropdown.Item as={Link} to={`/auth/logout`}>Logout</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	)
}

export default Header