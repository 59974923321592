//import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Header from './components/Header'
import Footer from './components/Footer'
import Login  from './components/Login';
import Logout  from './components/Logout';
import SampleFile from './components/SampleFile';
import Gpt4 from './components/Gpt4';
import Gpt35T16K from './components/Gpt35T16K';
import Gpt35T from './components/Gpt35T';
import AwsV1 from './components/AwsV1';
import AwsV2 from './components/AwsV2';
import EditPersona from './components/EditPersona';
import NotFound from './components/NotFound';
import { AuthenticationService } from './services/AuthenticationService';
import history from './services/History'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faCoffee, faPen, faBars, faTrash, faKey, faLock, faLockOpen, faFilter, faTimes, faSort, faSortUp, faSortDown, faUserAlt, faUserPlus, faSyncAlt, faCircle, faEye, faCheckCircle, faMinusCircle, faCaretLeft, faCaretDown, faCaretRight, faCommentMedical, faNotesMedical, faFileAlt, faUserMd, faCalendarAlt, faEllipsisV, faEllipsisH, faCommentAlt, faCommentDots, faChevronLeft, faChevronRight, faHome, faHeadphones, faCheck, faEyeSlash, faBan, faEnvelope, faAngleDoubleLeft, faAngleDoubleRight, faUserShield, faEdit, faAt, faPhoneSquareAlt, faUsers, faFileExcel, faFileExport, faFilePdf, faList, faFileArchive, faFileImage, faFileUpload, faBroom, faPrint, faHistory, faFolder, faFolderOpen, faImages, faTag, faTags, faTasks, faArrowRotateLeft, faGear, faEraser, faBookmark, faStar, faGlobe, faPlus } from '@fortawesome/free-solid-svg-icons'
library.add(faCheckSquare, faCoffee, faPen, faBars, faTrash, faKey, faLockOpen, faLock, faFilter, faTimes, faSort, faSortUp, faSortDown, faUserAlt, faUserPlus, faSyncAlt, faCircle, faEye, faCheckCircle, faMinusCircle, faCaretLeft, faCaretDown, faCaretRight, faCommentMedical, faNotesMedical, faFileAlt, faUserMd, faCalendarAlt, faEllipsisV, faEllipsisH, faCommentAlt, faCommentDots, faChevronLeft, faChevronRight, faHome, faHeadphones, faCheck, faEyeSlash, faBan, faEnvelope, faAngleDoubleLeft, faAngleDoubleRight, faUserShield, faEdit, faAt, faPhoneSquareAlt, faUsers, faFileExcel, faFileExport, faFilePdf, faList, faFileArchive, faFileImage, faFileUpload, faBroom, faPrint, faHistory, faFolder, faFolderOpen, faImages, faTag, faTags, faTasks, faArrowRotateLeft, faGear, faEraser, faBookmark, faStar, faGlobe, faPlus)

function App() {
  return (
    <Router history={history}> 
      { AuthenticationService.is_valid() && <Header /> }
      <main className="py-3_" style={{"minHeight": "calc(100vh - 105px)"}}>
          <Container>
            { !AuthenticationService.is_valid() &&
                  <Routes>
                    <Route exact path='/auth/login' element={<Login/>} />
                    <Route path="*" element={ <Navigate to="/auth/login" /> } />          
                  </Routes>
            }
            { AuthenticationService.is_valid() &&
              <Routes>
                 {/* <Route exact path='/' element={<SampleFile />} />
                <Route exact path='/home' element={<SampleFile />} /> */}
                <Route exact path='/' element={<Gpt35T />} />
                <Route exact path='/home' element={<Gpt35T />} />
                <Route exact path='/v1' element={<Gpt35T />} />
                <Route exact path='/v2' element={<Gpt35T16K />} />
                <Route exact path='/v3' element={<Gpt4 />} />
                <Route exact path='/v4' element={<AwsV1 />} />
                <Route exact path='/v5' element={<AwsV2 />} />
                <Route exact path='/editPersona' element={<EditPersona />} />
                <Route exact path='/auth/logout' element={<Logout/>} />
                <Route path="*" element={<NotFound/>} />
              </Routes>
            }
          </Container>
			</main>
      <Footer />
    </Router>
  );
}

export default App;
