import React, { useEffect } from 'react';
import LocalStorageService from '../services/LocalStorageService';
import http from '../services/HttpService';
import { AuthenticationService } from '../services/AuthenticationService';
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const localStorageService = LocalStorageService.getService();

function Logout() {
	const navigate = useNavigate();
	useEffect(() => {
		if (AuthenticationService.is_valid()) {
			logout();
		} else {
			navigate('/', { replace: true });
		}
	}, [])

	const logout = () => {
		http.post('/auth/logout', {
			"refresh_token": localStorageService.getRefreshToken()
		})
		.then(async function (response) {
			if(response.data['status']!=undefined) {
				if(response.data['status']=='success') {
					await AuthenticationService.logout();
					if (!AuthenticationService.is_valid()) {
						navigate(0)
					} else {
						console.log('Session not cleared');
					}
				} else {
					alert(response.data['message']);
				}
			}
		})
		.catch(function (e) {
			console.log(e);
		})
		.then(function () {
			// always executed
		});
	}

	return(
		<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
			<div className="card card-custom gutter-b example example-compact ">
				<div className="col-md-4 offset-md-4 text-center" style={{paddingTop: "50px", paddingBottom: "50px"}}>
					<span className='fs-18 font-weight-bold'>Logging out</span><Spinner animation="border" size="sm" className="ml-3"></Spinner>
				</div>
			</div>
		</div>
	);
}

export default Logout;