import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../images/fonicom-logo.png'

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col className="d-none text-center py-3 small">Powered by <img alt="Fonicom" src={logo} class="logo-sticky" style={{ height:20}}/></Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;