import React, { useState, useEffect, useRef } from "react";
import { Editor, EditorState, Modifier, ContentState, getDefaultKeyBinding } from "draft-js";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Col, Row, Form, Spinner, Dropdown, Button, DropdownButton, ButtonGroup, OverlayTrigger, Popover, Modal, Tabs, Tab } from "react-bootstrap";
import "draft-js/dist/Draft.css";
import RangeSlider from 'react-bootstrap-range-slider';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthenticationService } from '../services/AuthenticationService';
import { useNavigate } from "react-router-dom";
import http from '../services/HttpService';
import swal from 'sweetalert';
import StarRatings from 'react-star-ratings';
import axios from 'axios';

var defaultModel = "text-davinci-003";
var userZone = '';
var timezoneDisplay = '';
var defaultPageSize = 15;
var divHeight="350px";
let cancelToken;
let controller;
let cancelController = null;
//
var modelList = [
  {label:'AI Engine 1 - Full', value:'text-davinci-003'},
  {label:'AI Engine 2 - Medium', value:'text-davinci-002'},
  {label:'AI Engine 3 - Low', value:'text-curie-001'},
  {label:'AI Engine 4 - Very Low', value:'text-babbage-001'},
  {label:'AI Engine 5 - Others', value:'text-ada-001'}
];
var categoryList = [];
var tokenLength = {
  'text-davinci-003': 4000,
  'text-davinci-002': 4000,
  'text-curie-001': 2048,
  'text-babbage-001': 2048,
  'text-ada-001': 2048
};
var filtersRange = {
  randomness: {min: 0, max: 1, default: 0.7},
  maxLength: {min: 1, max: tokenLength, default: 256},
  frequency_penalty: {min:0, max: 2, default: 0},
  presence_penalty: {min:0, max: 2, default: 0}
}
const toasterOptions = {
  hideProgressBar : true,
  autoClose: 5000,
  closeButton: false,
  position: toast.POSITION.BOTTOM_LEFT,
  theme: "colored"
}

export default function MyEditor() {
  const navigate = useNavigate();
  useEffect(() => {
    if (AuthenticationService.is_valid()) {
      document.title = "Cognitista - Completion";
    } else {
      navigate('/auth/login', { replace: true });
    }
    userZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    timezoneDisplay = new Date().toTimeString().slice(9, 17);
    divHeight = (window.innerHeight - 335);
    var element = document.getElementsByClassName("public-DraftEditor-content");
    element[0].style.maxHeight = divHeight + "px";
    element[0].style.overflowY = "auto";
    //
    getTagsList();
    getCategoryList();
  }, []);

  const scrollRef = useRef(null);
  const [show, setShow] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showSaveForm, setShowSaveForm] = useState(false);

  const [params, setParams] = useState({
    ai_model: defaultModel,
    frequency_penalty: filtersRange.frequency_penalty.default,
    presence_penalty: filtersRange.presence_penalty.default,
    randomness: filtersRange.randomness.default,
    maxLength: filtersRange.maxLength.default,
    promptToken: 0,
    completionToken: 0,
    tokenCnt: 0,
    btnDisable: false,
    undobtnDisable: false,
    redobtnDisable: false,
    loading: false,
    tokenLoader: false,
    autoAdjustTokens: 1,
    historyId: 0,
    starRatingForm: false,
    starRatingLoader: false,
    stars: 0,
    savePromptLoader: false,
    category: '',
    tag: '',
    notes: ''
  });

  const [promptsObj, setPromptsObj] = useState({
    lastPrompt: '',
    lastPromptObj: '',
    oldPromptCnt: 0,
  });

  const [tokenObj, setTokenObj] = useState({
    maxLength: filtersRange.maxLength.default,
    promptToken: 0,
    completionToken: 0,
    tokenCnt: 0,
  });

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [editorOldState, setEditorOldState] = useState(() =>
    EditorState.createEmpty()
  );

  const [promptHistory, setPromptHistory] = useState({
    loading: false,
    total: 0,
    page: 1,
    data: {},
    timezone: '',
    lastPage: 0
  })

  const [savedPrompts, setSavedPrompts] = useState({
    loading: false,
    showSavedPrompts: false,
    page: 1,
    total: 0,
    data: {},
    timezone: '',
    tag: '',
    tagList: [],
    lastPage: 0
  })

  const editor = useRef(null);
  function focusEditor() {
    editor.current.focus();
  }

  const getEditorContent = () => {
    setEditorOldState(editorState);
    let input = editorState.getCurrentContent().getPlainText().trim();
    setPromptsObj({ ...promptsObj, lastPrompt: input });

    const currentPrompt = EditorState.createWithContent(editorState.getCurrentContent());
    //const newPromptFocused = EditorState.moveSelectionToEnd(currentPrompt);
    //setEditorState(newPromptFocused);
    const currentSelection = editorState.getSelection()
    
    /*const blockKey = currentSelection.getStartKey()
    let block = editorState.getCurrentContent().getBlockForKey(blockKey);
    console.log(block.text + '-' + block.text.length);
    const newSelection = currentSelection.merge({
      anchorOffset: block.text.length,
      focusOffset: block.text.length
    });*/

    const newEditorState = EditorState.forceSelection(currentPrompt, currentSelection)
    setEditorState(newEditorState);

    //
    setTimeout(function() {
      //const focusToEnd = EditorState.moveFocusToEnd(currentPrompt);
      //const focusToEnd = EditorState.moveSelectionToEnd(currentPrompt);
      //setEditorState(focusToEnd);
      //console.log(JSON.stringify(editorState.getSelection().toJS(), null, 4));

      sendAjaxRequest(input, '');
    }, 1000);
  }

  const clearEditorContent = () => {
    if (controller) {
      controller.abort();
    }
    const resetVal = EditorState.push(editorState, ContentState.createFromText(''));
    setEditorState(resetVal);
    setParams({ ...params, btnDisable: false, starRatingForm: false, stars: 0, undobtnDisable: false, redobtnDisable: false });
    setTokenObj({ ...tokenObj, promptToken: 0, completionToken: 0, tokenCnt: 0 });
  }

  const pushtext = (textToInsert, flg) =>{
    let currentContent = '';
    let currentSelection = '';
    if(flg==='') {
      currentContent = editorState.getCurrentContent();
      currentSelection = editorState.getSelection();
    } else if(flg==='regenerate') {
      currentContent = editorOldState.getCurrentContent();
      currentSelection = editorOldState.getSelection();
    }
    const blockKey = currentSelection.getStartKey()
    let block = currentContent.getBlockForKey(blockKey);
    currentSelection = currentSelection.merge({
      anchorOffset: block.text.length,
      focusOffset: block.text.length
    });
    //
    let newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      textToInsert
    );
    let newState = EditorState.push(editorState, newContent, 'insert-characters');
    const newEditorState = EditorState.moveFocusToEnd(newState, newContent.getSelectionAfter());
    setEditorState(newEditorState);
    // cursor positioning to the bottom of the scroll
    setTimeout(function() {
      var element = document.getElementsByClassName("public-DraftEditor-content"); 
      //element[0].scrollIntoView({behavior: "smooth"});
      element[0].scrollTop = 100000;
      //console.log('2 - ' + element[0].scrollHeight);
    }, 0, false);
  }

  const sendAjaxRequest = (input, flg) => {
    var tokensRequired = (parseInt(tokenObj.tokenCnt) + parseInt(tokenObj.maxLength));
    var avlblTokens = tokenObj.maxLength;
    //
    if(tokenObj.tokenCnt > tokenLength[params.ai_model]) {
      toast.error("Token length exceeded. Please reduce your prompt or completion length.", toasterOptions)
      return;
    }
    if(parseInt(filtersRange.maxLength.max[params.ai_model]) > 0) {
      if(tokensRequired > parseInt(filtersRange.maxLength.max[params.ai_model])) {
        if(params.autoAdjustTokens===1) {
          //avlblTokens = (parseInt(filtersRange.maxLength.max[params.ai_model]) - parseInt(params.tokenCnt));
          avlblTokens = parseInt(filtersRange.maxLength.max[params.ai_model]);
        } else if(params.autoAdjustTokens===0) {
          toast.error("Token length exceeded. Please reduce your prompt or completion length.", toasterOptions);
          return;
        }
      }
    } else {
      toast.error("Token length exceeded. Please reduce your prompt or completion length.", toasterOptions);
      return;
    }
    if(params.loading) {
      toast.error("Already a request is in progress", toasterOptions)
      return;
    }
    if(input==='') {
      toast.error("Prompt cannot be empty", toasterOptions)
      return;
    }
    const paramsObj = {
		prompt: input,
        ai_model: params.ai_model,
        randomness: params.randomness,
        maxLen: avlblTokens,
        f_penalty: params.frequency_penalty,
        p_penalty: params.presence_penalty,
        action: 'get_ai',
        typ: 'default'
	}
    setParams({ ...params, btnDisable: false, loading: true, undobtnDisable: true, redobtnDisable: true });    
    setTokenObj({ ...tokenObj, maxLength: avlblTokens });
    let url = '/_api/getCompletion';
    //
    cancelToken = axios.CancelToken.source();
    //
    cancelController = setTimeout(function() {
      http.post(url, paramsObj, {cancelToken: cancelToken.token})
      .then(res => {
        if(res.data.status === 'success') {
          if(res.data.result !== '') {
            pushtext(res.data.result, flg);
            setTimeout(function() {
              setParams({ ...params, btnDisable: false, loading: false, historyId: res.data.history_id, starRatingForm: true, stars: 0, undobtnDisable: false, redobtnDisable: false });
              setTokenObj({ ...tokenObj, maxLength: avlblTokens, completionToken: res.data.tokenCnt, tokenCnt: (res.data.tokenCnt + tokenObj.promptToken) });
            }, 750);
          } else {
            if(promptsObj.oldPromptCnt===tokenObj.promptToken) {
              toast.info("No changes made. Consider adjusting your prompt or stop sequences.", toasterOptions)
            } else if(res.data.tokenCnt===0) {
              toast.info("No result. Consider adjusting your prompt or stop sequences.", toasterOptions);
            }
            setParams({ ...params, btnDisable: false, loading: false, undobtnDisable: false, redobtnDisable: false });
            setTokenObj({ ...tokenObj, maxLength: avlblTokens });
          }
          setPromptsObj({ ...promptsObj, lastPrompt: input, oldPromptCnt: tokenObj.promptToken});
        } else if(res.data.status === 'error') {
          toast.error(res.data.result, toasterOptions);
          setParams({ ...params, btnDisable: false, loading: false, undobtnDisable: false, redobtnDisable: false });
          setTokenObj({ ...tokenObj, maxLength: avlblTokens });
        } else if(res.data.status === 'content-filter-error') {
          swal({
            title: "Content filter error!",
            text: res.data.result,
            icon: "error"
          });
          setParams({ ...params, btnDisable: false, loading: false, undobtnDisable: false, redobtnDisable: false });
          setTokenObj({ ...tokenObj, maxLength: avlblTokens });
        }
        clearTimeout(cancelController);
        cancelController = null;
      }).catch(function(e) {
      });
    }, 2500);
  }

  const cancelAjax = () => {
    // if (typeof cancelToken != typeof undefined) {
    //   cancelToken.cancel("Operation canceled due to new request.")
    // }
    setTimeout(function() {
      setParams({ ...params, btnDisable: false, loading: false });
      clearTimeout(cancelController);
      cancelController = null;
    }, 1000);
  }

  const handleKeyCommand = (command) => {
    //const newState = RichUtils.handleKeyCommand(editorState, command);
    if (command==='proceed') {
      getEditorContent();
      return 'handled';
    }
    return 'not-handled';
  }

  const keyBindingFn = (e) => {
    if (e.ctrlKey && e.keyCode === 13) {
      return 'proceed';
    }
    return getDefaultKeyBinding(e);
  }

  function myBlockStyleFn(contentBlock) {
    const type = contentBlock.getType();
    if (type === 'unstyled') {
      return 'defaultEditorStyle';
    }
  }

  const onChangeHandler = (e) => {
    var name = e.target?.name;
    var value = e.target?.value;
    validateFilterValues(name, value);
  }

  const onChangeSliderHandle = (e) => {
    var name = e.target?.name;
    var value = e.target?.value;
    validateFilterValues(name, value);
  }

  const onBlurHandler = (e) => {
    var name = e.target?.name;
    var value = e.target?.value;
    validateFilterValues(name, value);
  }

  const validateFilterValues = (name, value) => {
      if(name==='randomness') {
        var max_randomness = filtersRange.randomness.max;
        if(isNaN(value) || value>max_randomness || value<0 || value==='') {
          value = max_randomness;
        }
      }
      if(name==='maxLength') { 
        var max_token_len = filtersRange.maxLength.max[params.ai_model];
        if(isNaN(value) || value>max_token_len || value<0 || value==='') {
          value = max_token_len;
        }
        setTokenObj({ ...tokenObj, maxLength: value });
      }
      if(name==='frequency_penalty') {
        var max_fPenalty = filtersRange.frequency_penalty.max;
        if(isNaN(value) || value>max_fPenalty || value<0 || value==='') {
          value = max_fPenalty;
        }
      }
      if(name==='presence_penalty') {
        var max_pPenalty = filtersRange.presence_penalty.max;
        if(isNaN(value) || value>max_pPenalty || value<0 || value==='') {
          value = max_pPenalty;
        }
      }
      setParams({ ...params, [name]: value });
  }

  const modelHandler = selectedOption => {
    setParams({ ...params, ai_model: selectedOption.value, maxLength: filtersRange.maxLength.default });
    setTokenObj({ ...tokenObj, maxLength: filtersRange.maxLength.default });
  };

  const autoAdjust = (e) => {
    if(e.target.checked) {
      setParams({ ...params, autoAdjustTokens: 1 });
    } else {
      setParams({ ...params, autoAdjustTokens: 0 });
    }
  }

  const resetFilters = () => {
    setParams({
      ...params,
      ai_model: defaultModel,
      frequency_penalty: filtersRange.frequency_penalty.default,
      presence_penalty: filtersRange.presence_penalty.default,
      randomness: filtersRange.randomness.default,
      maxLength: filtersRange.maxLength.default
    });
    setTokenObj({ ...tokenObj, maxLength: filtersRange.maxLength.default });
  }

  const regeneratePrompt = () => {
    sendAjaxRequest(promptsObj.lastPrompt, 'regenerate');
  }

  const undoPrompt = () => {
    let currentContent = editorOldState.getCurrentContent();
    const prevPrompt = EditorState.createWithContent(currentContent);
    const newPromptState = EditorState.moveSelectionToEnd(prevPrompt);
    setEditorState(newPromptState);
  }

  const getTokens = (editorState) => {
    setEditorState(editorState);
    let input = editorState.getCurrentContent().getPlainText().trim();
    const paramsObj = {
        prompt: input,
        typ: 'default'
	}
    tokenLength = {
      'text-davinci-003': 4000,
      'text-davinci-002': 4000,
      'text-curie-001': 2048,
      'text-babbage-001': 2048,
      'text-ada-001': 2048
    };
    let url = '/_api/getTokens';
    if(input!=='') {
      controller = new AbortController();
      const signal = controller.signal;
      http.post(url, paramsObj, {signal})
      .then(res => {
        var tempVal = 0;
        if(parseInt(tokenLength[params.ai_model]) > parseInt(res.data.result)) {
          var tempVal = (parseInt(tokenLength[params.ai_model]) - parseInt(res.data.result));
        }
        filtersRange.maxLength.max[params.ai_model] = tempVal;
        setTokenObj({ maxLength: tempVal, promptToken: res.data.result, completionToken: 0, tokenCnt: res.data.result });
      }).catch(function(e) {
      });
    } else if(input==='') {
      setTokenObj({ ...tokenObj, promptToken: 0, completionToken: 0, tokenCnt: 0 });
    }
  }

  const getHistory = (page) => {
    var pageNo = (page!=='' && page>0) ? page : 1;
    setPromptHistory({ ...promptHistory, loading: true })
    let paramsObj = { uZone: userZone, page: pageNo };
    let url='/_api/getHistory';
    http.get(url, { params: paramsObj })
    .then(res => {
        var prevData = [];
        const propOwn = (pageNo===1) ? [] : Object.getOwnPropertyNames(promptHistory.data);
        if(propOwn.length === 0) {
          prevData = res.data.data;
        } else if(propOwn.length > 0) {
          prevData = promptHistory.data;
          for(var a=0;a<res.data.data.length;a++) {
            prevData.push(res.data.data[a]);
          }
        }
        setPromptHistory({ ...promptHistory, page: pageNo, total: res.data.total, lastPage: res.data.lastPage, data: prevData, timezone: timezoneDisplay, loading: false });
        if(!showHistory) {
          handleShowHistory();
        }
        setTimeout(function() {
          if(pageNo>1) {
            var divId = 'div-' + ((pageNo * defaultPageSize) - 5);
            //document.getElementById(divId).focus();
            document.getElementById(divId).scrollIntoView()
            //document.getElementById(divId).tabIndex = "-1";
          }
        }, 1000);
    });
  }

  const historyPageHandle = () => {
    getHistory(parseInt(promptHistory.page + 1));
  }

  const FillPrompt = (idx) => {
    var historyPrompt = promptHistory.data[idx]['prompt'];
    var pToken = promptHistory.data[idx]['others']['pToken'];
    var historyCompletion = promptHistory.data[idx]['completion'];
    var cToken = promptHistory.data[idx]['others']['cToken'];
    var totlTokens = (pToken + cToken);
    var stars = promptHistory.data[idx]['stars'];
    var history_id = promptHistory.data[idx]['id'];
    //var historyContent = (promptHistory.data[idx]['content']) ? promptHistory.data[idx]['content'] : '';
    var val = historyPrompt + historyCompletion;
    //
    const historyVal = EditorState.push(editorState, ContentState.createFromText(val));
    setEditorState(historyVal);
    setParams({ ...params, stars: stars, starRatingForm: true, historyId: history_id, undobtnDisable: true, redobtnDisable: true });
    setPromptsObj({ ...promptsObj, lastPrompt: val , oldPromptCnt: cToken });
    setTokenObj({ ...tokenObj, promptToken: pToken, completionToken: cToken, tokenCnt: totlTokens });
    //handleCloseHistory();
    
  }

  const saveRating = (newRating, name) => {
    setParams({ ...params, stars: newRating, starRatingLoader: true });
    const paramsObj = {
			stars: newRating,
      history_id: params.historyId
		}
    let url = '/_api/saveRating';
    http.post(url, paramsObj)
    .then(res => {
      if(res.data.status==='success') {
        toast.success("Rating submitted", toasterOptions)
      } else if(res.data.status==='already-added') {
        toast.info("Rating already added", toasterOptions)
      } else {
        toast.error("Something went wrong. Please try again.", toasterOptions)
      }
      setParams({ ...params, stars: newRating, starRatingLoader: false });
    });
  }

  const selectCategoryHandler = (e) => {
    setParams({ ...params, category: e.value });
  }

  const savePromptHandler = (e) => {
    var name = e.target?.name;
    var value = e.target?.value;
    setParams({ ...params, [name]: value });
  }

  const savePrompt = () => {
    const paramsObj = {
			category_id: params.category,
      tag: params.tag,
      notes: params.notes,
      history_id: params.historyId
		}
    let url = '/_api/savePrompt';
    http.post(url, paramsObj)
    .then(res => {
      if(res.data.status==='success') {
        toast.success("Prompt saved", toasterOptions)
        getTagsList();
        setTimeout(function() {
          handleCloseSaveForm();
        }, 1000);
      } else {
        toast.error("Something went wrong. Please try again.", toasterOptions)
      }
    });
  }

  const FillSavedPrompt = (idx) => {
    var historyPrompt = savedPrompts.data[idx]['prompt'];
    var pToken = savedPrompts.data[idx]['others']['pToken'];
    var historyCompletion = savedPrompts.data[idx]['completion'];
    var cToken = savedPrompts.data[idx]['others']['cToken'];
    var totlTokens = (pToken + cToken);
    var stars = savedPrompts.data[idx]['stars'];
    var history_id = savedPrompts.data[idx]['id'];
    var category = savedPrompts.data[idx]['category_id'];
    var tag = savedPrompts.data[idx]['tag'];
    var notes = savedPrompts.data[idx]['notes'];
    var val = historyPrompt + historyCompletion;
    //
    const historyVal = EditorState.push(editorState, ContentState.createFromText(val));
    setEditorState(historyVal);
    setParams({ ...params, stars: stars, starRatingForm: true, historyId: history_id, undobtnDisable: true, redobtnDisable: true, category: category, tag: tag, notes: notes });
    setPromptsObj({ ...promptsObj, lastPrompt: val , oldPromptCnt: cToken });
    setTokenObj({ ...tokenObj, promptToken: pToken, completionToken: cToken, tokenCnt: totlTokens });
  }

  const getSavedPrompts = (page) => {
    var pageNo = (page!=='' && page>0) ? page : 1;
    let url='/_api/getSavedPrompts';
    setSavedPrompts({ ...savedPrompts, showSavedPrompts: true, loading: true });
    let paramsObj = { uZone: userZone, page: pageNo };
    http.get(url, {paramsObj})
    .then(res => {
        var prevData = [];
        const propOwn = (pageNo===1) ? [] : Object.getOwnPropertyNames(savedPrompts.data);
        if(propOwn.length === 0) {
          prevData = res.data.data;
        } else if(propOwn.length > 0) {
          prevData = savedPrompts.data;
          for(var a=0;a<res.data.data.length;a++) {
            prevData.push(res.data.data[a]);
          }
        }
      setSavedPrompts({ ...savedPrompts, page: pageNo, total: res.data.total, lastPage: res.data.lastPage, data: prevData, timezone: timezoneDisplay, showSavedPrompts: true, loading: false });
      setTimeout(function() {
        if(pageNo>1) {
          var divId = 'div-sp-' + ((pageNo * defaultPageSize) - 5);
          document.getElementById(divId).scrollIntoView();
        }
      }, 1000);
    });
  }

  const savedPromptsPageHandle = () => {
    getSavedPrompts(parseInt(savedPrompts.page + 1));
  }

  const getTagsList = () => {
    let url = '/_api/getTags';
    http.get(url, {})
    .then(res => {
      if(res.data.status==='success') {
        setSavedPrompts({ ...savedPrompts, tagList: res.data.data });
      }
    });
  }

  const createCategory = (val) => {
    const paramsObj = {
			category: val
		}
    let url = '/_api/saveCategory';
    http.post(url, paramsObj)
    .then(res => {
      if(res.data.status==='success') {
        setParams({ ...params, category: res.data.id });
        categoryList = res.data.category;
      }
    });
  }

  const getCategoryList = () => {
    let url = '/_api/getCategories';
    http.get(url, {})
    .then(res => {
      if(res.data.status==='success') {
        categoryList = res.data.data;
      }
    });
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseHistory = () => setShowHistory(false);
  const handleShowHistory = () => setShowHistory(true);

  const handleShowSaveForm = () => {
    setShowSaveForm(true);
    const findindex = item => item.id === params.historyId;
    const idx = savedPrompts.data.findIndex(findindex);
    var category = savedPrompts.data[idx]['category_id'] ? savedPrompts.data[idx]['category_id'] : '';
    var tag = savedPrompts.data[idx]['tag'] ? savedPrompts.data[idx]['tag'] : '';
    var notes = savedPrompts.data[idx]['notes'] ? savedPrompts.data[idx]['notes'] : '';
    setParams({ ...params, category: category, tag: tag, notes: notes });
  }

  const handleCloseSaveForm = () => {
    setShowSaveForm(false);
    setParams({ ...params, category: '', tag: '', notes: '' });
  }

  const handleTabClick = (k) => {
      if(k==='history') {
        getHistory(1);
      } else if(k==='saved') {
        getSavedPrompts(1);
      }
  }
  
    return (
      <>
        <Modal size="sm" show={params.starRatingLoader} backdrop="static" keyboard={false}>
          <Modal.Body className="text-center">Processing <Spinner animation="border" size="sm"></Spinner></Modal.Body>
        </Modal>

        <Modal size="md" show={showSaveForm} backdrop="static" keyboard={false}>
          <Modal.Header>
              <Modal.Title>Save prompt</Modal.Title>
              <Button variant="outline-light" size="sm" type="button" onClick={handleCloseSaveForm} title="Close"><FontAwesomeIcon icon="times"/></Button>
          </Modal.Header>
          <Modal.Body>
            {params.savePromptLoader && <div className="text-center">Processing <Spinner animation="border" size="sm"></Spinner></div>}
            {!params.savePromptLoader && <>
              <div className="form-group mt-0">
                  <label>Category<span className="text-danger fs-11 ml-1">*</span></label>
                  <Creatable name="category" id="category" options={categoryList} onChange={selectCategoryHandler} onCreateOption={createCategory} value={categoryList.filter(option => option.value === params.category)} />
              </div>
              <div className="form-group mt-0">
                  <label>Tag<span className="text-danger fs-11 ml-1">*</span></label>
                  <input className="form-control form-control-sm" name="tag" id="tag" value={params.tag} onBlur={savePromptHandler} onChange={savePromptHandler}/>
                  <span className="text-muted fs-11 font-italic">Maximum 100 characters</span>
              </div>
              <div className="form-group mt-0">
                  <label>Notes</label>
                  <textarea className="form-control form-control-sm input-bdr" name="notes" id="notes" value={params.notes} onBlur={savePromptHandler} onChange={savePromptHandler} rows="4" maxlength="1000" style={{"resize": "none"}}></textarea>
                  <span className="text-muted fs-11 font-italic">Maximum 1000 characters</span>
              </div>
              <div className="form-group mb-0">
                  <Button variant="outline-light yellow-bg" className="yellow-bg" type="button" disabled={params.category==='' || params.tag===''} onClick={savePrompt}>Submit</Button>
                  <Button variant="outline-light" className="ml-2" type="button" onClick={handleCloseSaveForm}>Cancel</Button>
              </div>
            </>}
          </Modal.Body>
        </Modal>

        <div className={`offcanvas offcanvas-right p-10 mt-0 ${show ? "offcanvas-on" : ""}`}>
          <div className="offcanvas-header d-flex align-items-center justify-content-between mb-3" kt-hidden-height="24" >
              <h3 className="font-weight-bold m-0"><FontAwesomeIcon icon="gear" className="mr-2 yellow-clr"/>Settings</h3>
              <span onClick={handleClose} className="btn btn-xs btn-icon btn-light btn-hover-yellow" id="kt_quick_notifications_close">
                  <FontAwesomeIcon icon="times" />
              </span>
          </div>
          <div className="offcanvas-content pr-5 mr-n5 scroll ps ps--active-y" style={{"overflow-x": "hidden", "overflow-y": "auto", height:"85%"}}>
              <div className="navi navi-icon-circle navi-spacer-x-0">
                <div className="form-group mt-2">
                    <label>Model</label>
                    <Select name="ai_model" id="ai_model" options={modelList} onChange={modelHandler} value={modelList.filter(option => option.value === params.ai_model)} />
                </div>
                <div className="form-group">
                    <div className="row">
                      <label className="col-sm-8">Randomness</label>
                      <div className="col-sm-4">
                        <input className="form-control form-control-sm ht-30 text-right" name="randomness" id="randomness" value={params.randomness} onBlur={onBlurHandler} onChange={onChangeHandler} maxlength="4"/>
                      </div>
                    </div>
                    <RangeSlider variant='warning' style={{width: "100%"}} min={filtersRange.randomness.min} max={filtersRange.randomness.max} step="0.01" value={params.randomness} name="randomness" id="randomness" onChange={onChangeSliderHandle} tooltip="off" />
                </div>
                <div className="form-group">
                    <div className="row">
                      <label className="col-sm-8">Maximum length</label>
                      <div className="col-sm-4">
                        <input className="form-control form-control-sm ht-30 text-right" name="maxLength" id="maxLength" value={tokenObj.maxLength} onBlur={onBlurHandler} onChange={onChangeHandler}/>
                      </div>
                    </div>
                    <RangeSlider variant='warning' style={{width: "100%"}} min={filtersRange.maxLength.min} max={filtersRange.maxLength.max[params.ai_model]} value={tokenObj.maxLength} name="maxLength" id="maxLength" onChange={onChangeSliderHandle} tooltip="off" />
                </div>
                <div className="form-group mb-4">
                    <Form.Check className="cursor-pointer" type="checkbox" id="autoAdjust" name="autoAdjust" checked={params.autoAdjustTokens} label="Auto adjust token length" onChange={autoAdjust} />
                </div>
                <div className="form-group">
                    <div className="row">
                      <label className="col-sm-8">Frequency Penalty</label>
                      <div className="col-sm-4">
                        <input className="form-control form-control-sm ht-30 text-right" name="frequency_penalty" id="frequency_penalty" value={params.frequency_penalty} onBlur={onBlurHandler} onChange={onChangeHandler} maxlength="4"/>
                      </div>
                    </div>
                    <RangeSlider variant='warning' style={{width: "100%"}} min={filtersRange.frequency_penalty.min} max={filtersRange.frequency_penalty.max} step="0.01" value={params.frequency_penalty} name="frequency_penalty" id="frequency_penalty" onChange={onChangeSliderHandle} tooltip="off" />
                </div>
                <div className="form-group">
                    <div className="row">
                      <label className="col-sm-8">Presence Penalty</label>
                      <div className="col-sm-4">
                        <input className="form-control form-control-sm ht-30 text-right" name="presence_penalty" id="presence_penalty" value={params.presence_penalty} onBlur={onBlurHandler} onChange={onChangeHandler} maxlength="4"/>
                      </div>
                    </div>
                    <RangeSlider variant='warning' style={{width: "100%"}} min={filtersRange.presence_penalty.min} max={filtersRange.presence_penalty.max} step="0.01" value={params.presence_penalty} name="presence_penalty" id="presence_penalty" onChange={onChangeSliderHandle} tooltip="off" />
                </div>
                <div className="form-group mb-0">
                  <Button variant="outline-light" className="yellow-bg" type="button" onClick={resetFilters}>Reset</Button>
                </div>
              </div>
          </div>
        </div>
        <div className={show ? "offcanvas-overlay" : ""} onClick={handleClose}></div>

        <div tabindex="-1" className={`offcanvas offcanvas-right p-10 mt-0 ${showHistory ? "offcanvas-on" : ""}`}>
          <div className="offcanvas-header d-flex align-items-center justify-content-between mb-0" kt-hidden-height="24" >
              <span className="fs-11 text-muted font-weight-bold pb-4">{promptHistory.timezone}</span>
          </div>
          <div className="offcanvas-content pr-5 mr-n5 scroll ps ps--active-y" style={{"overflow-x": "hidden", "overflow-y": "auto", height:"85%"}}>
              <div className="navi navi-icon-circle navi-spacer-x-0">
                <Tabs className="mb-3" onSelect={handleTabClick}>
                  <Tab eventKey="history" title="History">
                      {promptHistory.loading && <div className="text-center mt-10">Loading <Spinner animation="border" size="sm" className="yellow-clr"></Spinner></div> }
                      {!promptHistory.loading && <>
                        {promptHistory.data.length === 0 && <div className="card card-custom gutter-b"><div className="card-body text-center my-4">No data available</div></div>}
                        <div id="historyContent">
                          {promptHistory.data.length > 0 && promptHistory.data.map((val, index) => {
                              return (
                                <>
                                  <div className="flex-grow" id={'div-'+index} key={index}>
                                      <div className="d-flex py-2 cursor-pointer" onClick={()=>FillPrompt(index)}>
                                        <div className="font-weight-bolder fs-11">
                                          <div className="">{val.created_date_tz}</div>
                                          <div className="">{val.prompt_time}</div>
                                        </div>
                                        <div className="ml-5 fs-12">
                                          <div>{val.prompt_label}</div>
                                          <div>
                                            <StarRatings rating={val.stars} starRatedColor="#f1a545" numberOfStars={5} starDimension="10px" starSpacing="0px" name='rating' />{val.saved===1 && <FontAwesomeIcon icon="bookmark" className="text-warning ml-2 fa-xs" title="Saved" style={{"verticalAlign":"-3px"}}/>}</div>
                                        </div>
                                      </div>
                                  </div>
                                </>
                              )
                          })}
                        </div>
                        {(promptHistory.total > defaultPageSize && promptHistory.lastPage === 0) && <>
                            <div className="text-right">
                                <div className="form-group mb-4">
                                    <span className="text-primary fs-13 font-weight-bold cursor-pointer" onClick={historyPageHandle}>View more</span>
                                </div>
                            </div>
                        </>}
                      </> }
                  </Tab>
                  <Tab eventKey="saved" title="Saved Prompts">
                      {savedPrompts.loading && <div className="text-center mt-10">Loading <Spinner animation="border" size="sm" className="yellow-clr"></Spinner></div> }
                      {!savedPrompts.loading && <>
                        {savedPrompts.data.length === 0 && <div className="card card-custom gutter-b"><div className="card-body text-center my-4">No data available</div></div>}
                        {savedPrompts.data.length > 0 && savedPrompts.data.map((val, index) => {
                            return (
                              <>
                                <div className="flex-grow" key={index}>
                                    <div className="d-flex py-2 fs-13 cursor-pointer" onClick={()=>FillSavedPrompt(index)}>
                                        <div>{val.prompt_label}
                                        <div>
                                          <span className="text-muted fs-11 mr-2">{val.created_on_tz}</span>
                                          <StarRatings className="savedStars float-right" rating={val.stars} starRatedColor="#f1a545" numberOfStars={5} starDimension="10px" starSpacing="0px" name='rating' />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                              </>
                            )
                        })}
                        {(savedPrompts.total > defaultPageSize && savedPrompts.lastPage === 0) && <>
                            <div className="text-right">
                                <div className="form-group mb-4">
                                    <span className="text-primary fs-13 font-weight-bold cursor-pointer" onClick={savedPromptsPageHandle}>View more</span>
                                </div>
                            </div>
                        </>}
                      </> }
                  </Tab>
                </Tabs>
              </div>
          </div>
        </div>
        <div className={showHistory ? "offcanvas-overlay" : ""} onClick={handleCloseHistory}></div>

        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
          <Row>
              <Col className="centerBox p-5" sm={{ span: 10, offset: 1}}>
                <Row>
                  <Col md={{ span: 7 }}>
                    <div>
                        <span className="fs-22 ml-5 font-weight-bold">
                        Cognitista <span className="fs-21 title-yellow-bg px-1 font-weight-boldest">AI</span>
                        </span>
                        <div className="d-none text-muted fs-10"><span className="ml-5">(Private beta ver 0.3)</span></div>
                    </div>
                  </Col>
                  <Col md={{ span: 5 }}>
                      <div className="d-flex align-items-center justify-content-end">
                        {/* {savedPrompts.tagList.length>0 && <span>
                          <Select placeholder="Select tag" name="select_tag" id="select_tag" className="tag" options={savedPrompts.tagList} onChange={selectTag} value={savedPrompts.tagList.filter(option => option.value === savedPrompts.tag)} />
                        </span>} */}
                        <span>
                          <OverlayTrigger
                            overlay={<Popover id="popover-basic">
                              <Popover.Header as="h3" className="yellow-bdr">Tokens</Popover.Header>
                              <Popover.Body>
                                <div>Prompt: {tokenObj.promptToken}</div>
                                <div>Completion: {tokenObj.completionToken}</div>
                                <div>Total: {tokenObj.tokenCnt}</div>
                              </Popover.Body>
                            </Popover>}
                            placement="left" >
                              <span className="d-inline-block yellow-bg yellow-br-3 fs-11 px-2 pt-2 pb-1 ml-2 mr-7">Tokens: {tokenObj.tokenCnt}</span>
                          </OverlayTrigger>
                        </span>
                      </div>
                  </Col>
                </Row>
                <div>
                  <div className="completions-container">
                      <div className="editor-container">
                          <div className="editorWidth RichEditor-editor mt-4 mb-2" onClick={focusEditor}>
                              <Editor
                                ref={editor}
                                editorState={editorState}
                                //onEditorStateChange={setEditorState}
                                onChange={getTokens}
                                placeholder=""
                                blockStyleFn={myBlockStyleFn}
                                handleKeyCommand={handleKeyCommand}
                                keyBindingFn={keyBindingFn}
                                spellCheck={true}
                              />
                              <div className="scrollDiv" ref={scrollRef}></div>
                          </div>
                      </div>
                  </div>
                  <div className="row mt-2 text-center">
                      <div className="col-md-3 text-center"></div>
                      <div className="col-md-6 text-center">
                          <Button variant="outline-light yellow-bg px-8" size="md" type="button" onClick={getEditorContent} disabled={params.btnDisable || params.loading} title="Submit">{(params.loading) ? <Spinner animation="border" size="sm"></Spinner> : <FontAwesomeIcon icon="caret-right" className="fa-lg" />}</Button>
                          <ButtonGroup className="customDropDown ml-2" size="md">
                            <Button variant="outline-light yellow-bg" size="md" type="button" onClick={cancelAjax} disabled={!cancelController || !params.loading} title="Cancel"><FontAwesomeIcon icon="ban" /></Button>
                            <Button variant="outline-light yellow-bg" size="md" type="button" disabled={params.loading || tokenObj.promptToken===0 || params.redobtnDisable} onClick={regeneratePrompt} title="Regenerate"><FontAwesomeIcon icon="sync-alt" /></Button>
                            <Button variant="outline-light yellow-bg" size="md" type="button" disabled={params.loading || tokenObj.promptToken===0 || params.undobtnDisable} onClick={undoPrompt} title="Undo Last"><FontAwesomeIcon icon="arrow-rotate-left" /></Button>
                            <Button variant="outline-light yellow-bg" size="md" type="button" disabled={params.loading || tokenObj.promptToken===0} onClick={clearEditorContent} title="Clear"><FontAwesomeIcon icon="eraser" /></Button>
                            <Button variant="outline-light yellow-bg" size="md" type="button" disabled={params.loading} onClick={handleShow} title="Settings"><FontAwesomeIcon icon="gear" /></Button>
                            <DropdownButton as={ButtonGroup} title={<FontAwesomeIcon icon="ellipsis-h" />} variant="outline-light yellow-bg" size="md" disabled={params.loading}>
                                <Dropdown.Item eventKey="1" className="fs-12"><FontAwesomeIcon icon="star" className="text-secondary mt-1 mr-2"/>Examples</Dropdown.Item>
                                <Dropdown.Item eventKey="2" className="fs-12" onClick={()=>getHistory(1)}><FontAwesomeIcon icon="history" className="text-secondary mt-1 mr-2"/>History</Dropdown.Item>
                                <Dropdown.Item eventKey="2" disabled={params.loading || tokenObj.promptToken===0} className="fs-12" onClick={handleShowSaveForm}><FontAwesomeIcon icon="bookmark" className="text-secondary mt-1 mr-2"/>Save</Dropdown.Item>
                            </DropdownButton>
                          </ButtonGroup>
                      </div>
                      <div className="col-md-3 text-right">
                        <div className="mt-2 mr-5">
                          {params.starRatingForm && <StarRatings rating={params.stars} starRatedColor="#f1a545" changeRating={saveRating} numberOfStars={5} starDimension="25px" starSpacing="0px" name='rating' />}
                        </div>
                      </div>
                  </div>
                </div>
              </Col>
          </Row>
          <ToastContainer />
        </div>
      </>
    );
}