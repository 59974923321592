import { BehaviorSubject } from 'rxjs';
import LocalStorageService from './LocalStorageService';
import http from './HttpService'

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

const localStorageService = LocalStorageService.getService();

export const AuthenticationService = {
    login,
    logout,
	is_valid,
    get_role,
    get_token,
    get_name,
    get_rights,
    has_rights,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value },
};

function is_valid() {
    let user = currentUserSubject.value;
    //console.log(user);
    if(!user) {
        return false;
    }
    if(user.authenticated!==undefined && user.authenticated===true) {
        return true;
    }
    return false;
}

function get_token() {
    let user = currentUserSubject.value;
    //console.log(user);
    if(!user) {
        return null;
    }
    if(user.access_token!==undefined && user.access_token!=='') {
        return user.access_token;
    }
    return null;
}

function get_role() {
    let user = currentUserSubject.value;
    if(!user) {
        return null;
    }
    if(user.role!==undefined && user.role!=='') {
        return user.data.user_type;
    }
    return null;
}

function has_rights(param) {
    let user = currentUserSubject.value;
    if(!user) {
        return false;
    }
    if(user.data!==undefined && user.data!=='') {
        return user.data[param] === true
    }
    return false;
}

function get_rights() {
    let user = currentUserSubject.value;
    if(!user) {
        return null;
    }
    if(user.data!==undefined && user.data!=='') {
        return {
            is_admin : (user.data.user_type==='ADMIN') ? true : false
        }
    }
    return null;
}

function get_name() {
    let user = currentUserSubject.value;
    if(!user) {
        return null;
    }
    if(user.data!==undefined && user.data!=='') {
        return user.data.firstname;
    }
    return null;
}


function login(user) {


    let user_data = user;
    //console.log(user_data);
    localStorage.setItem('currentUser', JSON.stringify(user_data));
    currentUserSubject.next(user_data);
    localStorageService.setToken(user_data)

	
}

function logout() {
    //remove user from local storage to log user out
    //localStorage.removeItem('currentUser');
    localStorage.clear();
    sessionStorage.clear();
    currentUserSubject.next(null);
    localStorageService.clearToken();
}
