
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { AuthenticationService } from "./AuthenticationService";
import LocalStorageService from "./LocalStorageService";
//import { useHistory } from "react-router-dom";

//import history from './History'
// console.log(window.location)
// console.log(process.env.REACT_APP_BASE_URL)

const config = {
	baseURL: process.env.REACT_APP_BASE_URL,

}



const axiosInstance = axios.create({
	baseURL: config.baseURL,
	headers: {
		'crossDomain': true,
		'X-Requested-With': 'XMLHttpRequest',

		//'Authorization': 'bearer ' + authenticationService.get_token()
	},
	// proxy: {
	// 	host: config.host,
	// 	port: config.port
	// }
});


// LocalstorageService
const localStorageService = LocalStorageService.getService();

// Add a request interceptor
axiosInstance.interceptors.request.use(
	config => {
		const token = localStorageService.getAccessToken();
		if (token) {
			config.headers['Authorization'] = 'Bearer ' + token;
		}
		// config.headers['Content-Type'] = 'application/json';
		return config;
	},
	error => {
		Promise.reject(error)
	});



//Add a response interceptor

axiosInstance.interceptors.response.use((response) => {
	return response
}, function (error) {
	const originalRequest = error.config;

	console.log(originalRequest.url)
	if (error.response.status === 401 && originalRequest.url === '/auth/token') {
		//console.log('block1');
		/* const navigate = useNavigate();
		navigate('/', {replace: true}); */


		AuthenticationService.logout();
		window.location.reload();

		return Promise.reject(error);
	}

	if (error.response.status === 401 && !originalRequest._retry) {
		console.log('block2');;
		originalRequest._retry = true;
		const refreshToken = localStorageService.getRefreshToken();
		return axiosInstance.post('/auth/token',
			{
				"refresh_token": refreshToken
			})
			.then(res => {
				//console.log(res);
				if (res.status === 201) {
					localStorageService.setToken(res.data);
					axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();
					//return axios(originalRequest);
					return axiosInstance(originalRequest);
				} else if (res.status === 401) {
					if (res.action !== undefined) {
						if (res.action === 'redirect') {
							console.log('redirect');
							/* const navigate = useNavigate();
							navigate('/', {replace: true}); */
							AuthenticationService.logout();
							window.location.reload();
						}
					}
					return Promise.reject(error);
				}
			})
	}
	return Promise.reject(error);
});

export default axiosInstance;

const CancelToken = axios.CancelToken;

export function createCancelTokenHandler(_function) {
	//console.log(name)
	// initializing the cancel token handler object
	const cancelTokenHandler = {};

	// initializing the cancel token of the request
	// const cancelTokenRequestHandler = {
	// 	cancelToken: undefined
	// }

	let functions = []
	if (Array.isArray(_function)) {
		functions = _function
	} else {
		functions.push(_function)
	}
	//console.log(functions)

	functions.forEach((func) => {

		const cancelTokenRequestHandler = {
			cancelToken: undefined
		}

		//associating the cancel token handler to the request name
		cancelTokenHandler[func] = {
			handleRequestCancellation: () => {
				// if a previous cancel token exists,
				// cancel the request
				cancelTokenRequestHandler.cancelToken && cancelTokenRequestHandler.cancelToken.cancel(`${func} canceled`)

				// creating a new cancel token
				cancelTokenRequestHandler.cancelToken = CancelToken.source();

				// returning the new cancel token
				return cancelTokenRequestHandler.cancelToken;
			}
		}
	});

	// associating the cancel token handler to the request name
	// cancelTokenHandler[name] = {
	// 	handleRequestCancellation: () => {
	// 		// if a previous cancel token exists,
	// 		// cancel the request
	// 		cancelTokenRequestHandler.cancelToken && cancelTokenRequestHandler.cancelToken.cancel(`${name} canceled`)

	// 		// creating a new cancel token
	// 		cancelTokenRequestHandler.cancelToken = CancelToken.source();

	// 		// returning the new cancel token
	// 		return cancelTokenRequestHandler.cancelToken;
	// 	}
	// }

	return cancelTokenHandler;
}

