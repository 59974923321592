import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

export default function NotFound() {
	const navigate = useNavigate();
	useEffect(() => {
		redirect();
	}, [])
	const redirect = () => {
		setTimeout(function() {
			navigate('/')
		}, 500)
	}
    return (
		<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
			<div className="card card-custom gutter-b example example-compact ">
				<div className="col-md-12" style={{paddingTop: "50px",paddingBottom: "50px",textAlign: "center"}}>
          			{/* <h1>404</h1>
					<h4>Sorry!!! The page you are looking for is not found!!!</h4> */}
					<span>Redirecting...Please wait...</span>
				</div>
			</div>
		</div>
    )
}