import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

// import './index.css';
import './css/style.css'
import './css/custom.css';
import './css/font.css';
import './css/ani.css';
import './css/template2.css'



const root = ReactDOM.createRoot(document.getElementById('root'));
const strictMode = process.env.NODE_ENV === 'PROD';
root.render(
  (strictMode && (<React.StrictMode><App /></React.StrictMode>)) || <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
