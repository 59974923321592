import  React, { useState, useEffect  } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { AuthenticationService } from '../services/AuthenticationService';
import http from '../services/HttpService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import login_bg from '../images/brain.png';
import MicrosoftLogin from "react-microsoft-login";

const clientId ="894439786054-t2au3hc8dpfur3uljovm6pm5t290f6h0.apps.googleusercontent.com";
const office365clientId = '7a257e2b-9d09-4103-a9fe-b9bcd9355d93'; // New app registered by John

const toasterOptions = {
  hideProgressBar : true,
  autoClose: 2000,
  closeButton: false,
  position: toast.POSITION.BOTTOM_LEFT,
  theme: "colored"
}

export default function Login() {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Cognitista - Login";
    function start() {
      gapi.client.init({
      clientId:clientId,
      scope: 'email',
        });
    }
    gapi.load('client:auth2', start);
    if (AuthenticationService.is_valid()) {
        navigate('/', {replace: true});
        //return;
    } else {
      //return;
    }
  }, []);

  const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
  const [onReq, setOnReq] = useState(false);

  const handleFields = (event) => {
		if(event.target.name)
		switch (event.target.name) {
			case 'email':
				setEmail(event.target.value);
				break;

			case 'password':
				setPassword(event.target.value);
				break;

			default:
				break;
		}
	}

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let params={
      email: data.get('email').trim(),
      password: data.get('password').trim(),
    };
    sendAjaxRequest(params);
  };

  const redirectToHome = () => {
    navigate('/home', {replace: true});
  }

  const sendAjaxRequest = (event) =>{
    let url = '/auth/login';
		let data = {
			email: email,
			password: password
		}
		setOnReq(true);
    http.post(url, data)
    //axios.post('http://192.168.27.203:4000/auth/login',data)
    .then(async function (response) {
      if(response.data['status']!== undefined) {
          if(response.data['status']==='success'){
            let user_data = response.data.data;
            await AuthenticationService.login(user_data);
						if (AuthenticationService.is_valid()) {
              navigate(0);
            } else {
              toast.error("Login failed. Please try again.", toasterOptions)
              setOnReq(false)
            }
          } else{
            toast.error("Login failed. " + response.data['message'], toasterOptions)
            setOnReq(false)
          }
      }
    })
    .catch(function (error) {})
    .then(function () {});
  }

  const googleSuccess = (res) => {
    http.post('/auth/gmail', res.profileObj)
      .then(async function (response) {
        if (response.data['status'] != undefined) {
          if (response.data['status'] === 'success') {
            let user_data = response.data.data;
            await AuthenticationService.login(user_data);
            if (AuthenticationService.is_valid()) {
              //navigate('/home', {replace: true});
              navigate(0);
            } else {
              toast.error("Login failed. Please try again.", toasterOptions)
              setOnReq(false)
            }
          } else {
            toast.error("Login failed. " + response.data['message'], toasterOptions)
            setOnReq(false)
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    
  }

  const googleFailure = (response) => {
    console.log(response);
  }

  const office365Success = (err, res, obj) => {
    if(err) {
      console.log('Office365 login error - ' + err);
    } else {
      if(res) {
        console.log('Office365 login success');
        var params = {
          name: res.account.name,
          email: res.account.userName
        }
        http.post('/auth/office365', params)
        .then(async function (response) {
            if (response.data['status'] != undefined) {
              if (response.data['status'] === 'success') {
                let user_data = response.data.data;
                await AuthenticationService.login(user_data);
                if (AuthenticationService.is_valid()) {
                  navigate(0);
                } else {
                  toast.error("Login failed. Please try again.", toasterOptions)
                  setOnReq(false)
                }
              } else {
                toast.error("Login failed. " + response.data['message'], toasterOptions)
                setOnReq(false)
              }
            }
        })
        .catch(function (error) {
          console.log(error);
        });
      } else {
        console.log('Office365 login error - No data');
      }
    }
  }
  
  return (
    <>
      <div className="align-items-center ">
      <div className="content d-flex flex-column flex-column-fluid d-flex ">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <div className="login-form animated zoomIn align-items-center">
              <form onSubmit={handleSubmit} className="form text-left fv-plugins-bootstrap fv-plugins-framework" id="kt_login_signin_form">
                  <div className="text-center">
                      <div className="fs-22 font-weight-bolder">
                          Cognitista <span className="fs-23 title-yellow-bg px-1">AI</span>
                      </div>
                      <div className="text-muted fs-10">(Private beta ver 0.3)</div>
                      <div className="fs-18">Login</div>
                  </div>
                  <div className="form-group mt-5 mb-5 fv-plugins-icon-container has-danger">
                      <input type="text" className="form-control h-auto px-2 placeholder-dark-75 placeholder-dark-75" placeholder="Email" autoComplete="off" name="email" value={email} onChange={handleFields}/>
                  </div>
                  <div className="form-group mt-5 mb-5">
                      <input type="password" className="form-control h-auto px-2 placeholder-dark-75" placeholder="Password" name="password" autoComplete="off" value={password} onChange={handleFields} />
                  </div>
                  <div className="text-center mt-10">
                      <div>
                          <button type="submit" className="btn btn-primary shadow-sm font-weight-bold" disabled={onReq}>Continue {onReq ? <Spinner animation="border" size="sm" className="ml-1"></Spinner> : <FontAwesomeIcon icon="angles-right" className="ml-1"/>}</button>
                      </div>
                      <br></br>
                      <div>
                        <GoogleLogin
                            clientId={clientId}
                            buttonText="Continue with Google"
                            onSuccess={googleSuccess}
                            onFailure={googleFailure}
                            cookiePolicy={'single_host_origin'}
                          />
                      </div>
                      <br></br>
                      <div><MicrosoftLogin clientId={office365clientId} authCallback={office365Success} /></div>
                  </div>
              </form>
              </div>
          </div>
        </div>
        <ToastContainer />
      </div>
      </div>
    </>
  );
}